@import "../../sass/variables";

.page__contact {
  padding: 15px 10%;
  text-align: center;
  width: 100%;
  color: $color-charcoal;

  .title {
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 29px;
  }
  .desc {
    margin-top: 24px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }

  .contact-info {
    margin-top: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    width: 100%;

    + .contact-info {
      margin-top: 0px;
    }

    svg:first-child {
      margin-right: 10px;
      font-size: 24px;
      margin-top: -6px;
    }
  }

  .page__next__button {
    margin-top: 24px;

    .button {
      max-width: 350px;
      width: 100%;
      align-self: center;
      &:focus {
        outline: none !important;
      }
      & + .button {
        margin-top: 10px;
      }
    }
  }
}
