@import "../../sass/variables";

.encore__kyc__result {
  padding: 15px 10%;
  text-align: center;
  width: 100%;
  color: $color-charcoal;

  .title {
    font-size: 32px;
    font-weight: 800;
    margin-top: 136px;
  }
  .desc {
    font-size: 16px;
    margin-top: 16px;
  }
  .remarks {
    font-size: 12px;
    max-width: 50%;
    margin: 32px auto -10px;
    font-weight: 700;
  }
  .banner {
    margin-top: 26px;
    img {
      width: 100%;
      object-fit: cover;
      max-width: 746px;
    }
  }
}

//Mobile Phone CSS
@media only screen and (max-width: 768px) {
  .encore__kyc__result {
    padding: 15px;
  }
}
