@import "../../sass/variables";

.maintenance {
  padding: 15px 10%;
  text-align: center;
  width: 100%;
  color: $color-charcoal;
  max-width: calc(700px + 20%);

  .logo-container {
    margin-top: 26px;
    img {
      width: 100%;
      object-fit: cover;
      max-width: 320px;
    }
  }

  .title {
    font-size: 32px;
    font-weight: 800;
    margin-top: 42px;
  }

  .desc {
    font-size: 16px;
    margin-top: 16px;
  }
}

//Mobile Phone CSS
@media only screen and (max-width: 768px) {
  .maintenance {
    padding: 15px;
  }
}
