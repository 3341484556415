@import "../../sass/variables";

.encore__kyc__loading {
  padding: 15px 10%;
  text-align: center;
  width: 100%;
  color: $color-charcoal;

  .progress-container {
    display: flex;
    justify-content: center;
    margin-top: 42px;
    .progress {
      display: -ms-flexbox;
      display: flex;
      height: 0.65rem;
      overflow: hidden;
      font-size: 0.75rem;
      background-color: $color-grey-g300;
      border-radius: 0.5rem;
      width: 600px;
    }
    .blue-testing {
      background-color: #05a0d3 !important;
    }
    .green-testing {
      background-color: #05d3b7 !important;
    }
    .light-blue-testing {
      background-color: #05bed3 !important;
    }
  }
  .spinner {
    margin-top: 64px;
    img {
      width: 48px;
    }
  }
  .title {
    font-size: 32px;
    font-weight: 800;
    margin-top: 24px;
  }
  .desc {
    font-size: 16px;
    margin-top: 24px;
  }
  .loading {
    margin: 20px 34% 0px 34%;
    height: 10px;
    overflow: hidden;
    border-radius: 6px;
    .blue {
      position: relative;
      float: left;
      width: 0%;
      height: 100%;
      background: #05a0d3;
      transition: width 4s;
    }
    .green {
      position: relative;
      float: left;
      width: 0%;
      height: 100%;
      background: #05d3b7;
      transition: all 4s;
    }
    .light-blue {
      position: relative;
      float: left;
      width: 0%;
      height: 100%;
      background: #05bed3;
      transition: all 4s;
    }
  }
  .loading-text {
    color: $color-tertiary-dark;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    margin-top: 10px;
    animation: fadeIn ease 3s;
    -webkit-animation: fadeIn ease 1s;
    margin-bottom: 52px;
  }
  .movableText {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    margin-top: 10px;
    // animation: floatup 1s forwards;
    // -webkit-animation: floatup 1s forwards;
    // position: relative;
  }
  .after-load-container {
    display: flex;
    justify-content: center;
  }
  .table-style {
    width: 300px;
  }
  .kyc__reminder__container {
    margin-top: 80px;
    justify-content: center;
    display: flex;
    flex-flow: column;
    align-items: center;

    .reminder__title {
      font-size: 14px;
      font-weight: 800;
      color: $color-primary;
      text-transform: uppercase;
      text-align: left;
    }

    .reminder__border {
      margin-top: 15px;

      .reminder__desc {
        font-size: 14px;
        color: $color-primary;
        border-radius: 10px;
        padding: 15px;
        // white-space: nowrap;
        border: 1px solid $color-primary;
        display: block;
        text-align: left;
        img {
          padding-right: 10px;
          margin-top: -10px;
        }
        span {
          line-height: 24px;
        }
      }
    }
  }
  @keyframes floatup {
    from {
      transform: translateY(0px);
    }
    to {
      opacity: 0;
      display: none;
      transform: translateY(50px);
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

//Mobile Phone CSS
@media only screen and (max-width: 768px) {
  .encore__kyc__loading {
    padding: 15px;
    .progress-container {
      .progress {
        width: 100% !important;
      }
    }
    .title {
      font-size: 26px;
    }
    .loading {
      margin: 20px 0px 0px 0px;
    }
  }
}
