@import "../../sass/variables";

.email-comp {
  padding: 15px 10%;
  text-align: center;
  width: 100%;
  color: $color-charcoal;

  .banner {
    margin-top: 64px;
    img {
      width: 100%;
      object-fit: cover;
      max-width: 320px;
    }
  }
  .title {
    font-size: 32px;
    font-weight: 800;
    margin-top: 24px;
  }

  .desc {
    font-size: 16px;
    margin-top: 16px;
  }
}
