// official boost palette
$color-boost-red: #ea0029;
$color-fade-red: #f8a6b4;
$color-teal: #10cfc9;
$color-dark-teal: #15a5a1;
$color-fade-teal: #6fe2df;
$color-yellow: #ffc629;
$color-dark-yellow: #d09418;
$color-fade-yellow: #ffd282;
$color-charcoal: #231f20;
$color-grey-g50: #58595b;
$color-grey-g100: #8a8a8c;
$color-grey-g200: #cdcdcd;
$color-grey-g300: #f3f4f4;
$color-grey-g400: #f9f9f9;
$color-pure-white: #ffffff;

// custom boost palette
$color-custom-boost-red-dark: #bd0a0a;
$color-custom-blue-dark: #124a67;
$color-fade-yellow-pale: #f5a71e1a;

// local palette
$color-white: $color-pure-white;
$color-primary-light: $color-fade-red; // old: #3caed9;
$color-primary: $color-boost-red; // old: #0c9ad0;
$color-primary-dark: $color-custom-boost-red-dark; // old: #086b91;
$color-secondary-light: $color-grey-g100; // old: #f8b94a;
$color-secondary: $color-grey-g50; // old: #f7a81d;
$color-secondary-dark: $color-charcoal; // old: #ac7514;
$color-tertiary-dark: $color-custom-blue-dark;

// use case palette
$color-footer-background: $color-charcoal; // old: #414040;
$color-input-label-text: $color-grey-g50;
$color-input-value-text: $color-charcoal;
$color-input-value-text-disabled: $color-grey-g100;
$color-input-select-placeholder: $color-grey-g100;
$color-input-border-active: $color-primary;
$color-input-bg: $color-grey-g400;
$color-input-border-hover: $color-grey-g100;
$color-input-border-inactive: $color-grey-g200;
$color-error: $color-boost-red;
$color-notes: $color-boost-red;
$color-outlined-button-text-border: $color-grey-g100;
$color-outlined-button-text-bg: $color-white;
$color-outlined-button-text-bg-hover: $color-grey-g300;
$color-outlined-button-fab-bg: $color-grey-g300;
$color-outlined-button-fab-bg-hover: $color-grey-g200;
$color-stepper-background: $color-grey-g200;
$color-modal-title-text: $color-white;
$color-modal-content-text: $color-charcoal;
$color-divider: $color-grey-g200;
