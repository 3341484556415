@import '../../sass/variables';

.leadgen.sorry{
    margin-top: 6%;
    padding: 10px;
    .leadGen-sorry-title{
        font-size: 26px;
        color: $color-charcoal;
        font-weight: bold;
    }
    .leadGen-sorry-desc{
        font-size: 14px;
        color: $color-charcoal;
    }
    .leadGen-sorry-button-container{
        height: 48px;
        .button{
            width: 100%;
        }
    }
}

@media only screen
and (min-width : 350px) {
    .sorry{
        margin-top: 6%;
        padding: 10px;
        .leadGen-sorry-title{
            font-size: 26px;
            color: $color-charcoal;
            font-weight: bold;
        }
        .leadGen-sorry-desc{
            font-size: 14px;
            color: $color-charcoal;
        }
        .leadGen-sorry-button-container{
            height: 48px;
            text-align: center;
            .button{
                width: 300px;
            }
        }
    }
}