@import "./sass/base";

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: $color-white;
// }

// .App-link {
//   color: #61dafb;
// }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.formik-form {
  margin-top: 25px;

  .mobile-no-index {
    padding: 10px 10px 10px 0px;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
  }

  .checkbox-container {
    margin-left: 11px;
  }

  .camera-card-container {
    border: 2px solid #61caed;
    background-color: #005b8b14;
    border-radius: 10px;
    width: 60vh;
    text-align: center;

    &.error {
      border: 2px solid $color-error;
      background-color: $color-grey-g400;
    }
    &.success {
      border: 2px solid #05d3b7;
      background-color: $color-grey-g400;
    }
    .flex {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;

      &.before {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .button-container-en {
        margin-right: unset;
        padding: 10px;
        cursor: pointer;
      }

      .button-container-ms {
        margin-right: unset;
        padding: 10px;
        cursor: pointer;
      }
      .picture-holder {
        width: 36vh;
        margin-left: unset;
      }
    }
  }
}

.formLabel {
  color: $color-charcoal;
  // font-family: axiatamedium;
  font-size: 17px;
}

.errorLabel {
  color: $color-error;
}

.hidden {
  display: none;
}

.border {
  border: 1px solid;
  border-radius: 10px;
  padding: 20px;
  &.light-blue {
    border-color: #99b5c5;
  }
}

.underline {
  text-decoration: underline;
}

.font-colour-faux {
  color: $color-grey-g50;
  font-size: 12px;
  overflow-wrap: anywhere;
}

//Mobile Phone CSS
@media only screen and (max-width: 768px) {
  .formik-form {
    .camera-card-container {
      width: 34vh;

      .flex {
        .picture-holder {
          width: 28vh;
        }
      }
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 568px) {
  .formik-form {
    .camera-card-container {
      width: 48vh;
      .flex {
        .picture-holder {
          width: 38vh;
        }
      }
    }
  }
}

@media only screen and (min-width: 569px) and (max-width: 768px) {
  .formik-form {
    .camera-card-container {
      width: 54vh;
      .flex {
        .picture-holder {
          width: 36vh;
        }
      }
    }
  }
}
