@import "../../sass/variables";

.language-toggle-group {
  .language-toggle {
    border: 1px solid $color-primary;
    color: $color-primary;
    outline: none;
    height: 36px;

    span {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }

    &.Mui-selected {
      background-color: $color-primary;
      color: $color-white;

      &:hover {
        background-color: $color-primary;
      }
    }
  }
}