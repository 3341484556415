@import "../../sass/variables";

.leadgen.maintenance {
  padding: 15px 10%;
  text-align: left;
  width: 100%;

  .leadGenTitle {
    margin-top: 2%;
    font-size: 38px;
    color: $color-charcoal;
  }

  .desc {
    margin-top: 10px;
    font-size: 21px;
    color: $color-charcoal;
  }

  .logo-container {
    img {
      width: 100%;
    }
  }

  .form-flex {
    .item {
      max-width: 450px;
      display: flex;
    }
  }

  .leadGen-button-container {
    margin: 35px 0px 0px 0px;

    & + .leadGen-button-container {
      margin: 15px 0px 0px 0px;
    }

    .button {
      max-width: 450px;
      width: 100%;
      align-self: center;
      &:focus {
        outline: none !important;
      }
      & + .button {
        margin-top: 10px;
      }
    }
  }

  .numberCodeContainer {
    background: $color-grey-g300 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 8px;
    height: 48px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
  }
}

//Mobile Phone CSS
@media only screen and (max-width: 768px) {
  .leadgen.maintenance {
    padding: 15px;
  }
}
