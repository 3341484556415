@import '../../sass/variables';

.leadgen.thankyou{
    margin-top: 6%;
    .leadGen-thankyou-title{
        font-size: 26px;
        color: $color-charcoal;
        font-weight: bold;
    }
    .leadGen-thankyou-subTitle{
        font-size: 14px;
        color: $color-charcoal;
        max-width: 552px;
        margin: auto;
    }
    .leadGen-thankyou-desc{
        font-size: 14px;
        color: $color-charcoal;
    }
    .button-container{
        margin-top: 4%;
        .button{
            // width: 100%;
        }
    }
}