@import "../../sass/variables";

.header-parent {
  padding: 20px 15px 10px 15px;

  .header3 {
    display: flex;
    padding: 0px 10%;

    .question-holder {
      display: none;
    }

    .logo-holder {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        max-width: 400px;
        max-height: 120px;
        object-fit: contain;
      }
    }

    .language-holder {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .language {
        border: 2px solid transparent;
        border-radius: 5px;
        height: fit-content;

        // label {
        //   margin: 0px;
        //   width: 30px;
        //   padding: 2px 2px 2px 2px;
        //   color: rgb(221, 59, 143);
        //   background-color: transparent;
        //   cursor: pointer;

        //   &:hover {
        //     color: $color-white;
        //     background-color: rgba(221, 59, 143, 0.8);
        //   }

        //   &.selected {
        //     &,&:hover {
        //       color: $color-white;
        //       background-color: rgb(221, 59, 143);
        //     }
        //   }
        // }
      }
    }
  }
}

@media (max-width: 768px) {
  .header-parent {
    .header3 {
      padding: 0px 15px;

      .logo-holder {
        img {
          max-width: 200px;
        }
      }
    }
  }
}
