@import "../../sass/variables";

.encore__kycBefore {
  padding: 15px 10%;
  text-align: left;
  width: 100%;

  #smallDesc {
    font-size: 14px;
    color: $color-charcoal;
  }
}

//Mobile Phone CSS
@media only screen and (max-width: 768px) {
  .encore__kycBefore {
    padding: 15px;
  }
}
