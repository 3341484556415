@import "../../sass/variables";

.encore__otp__guide {
  padding: 15px 10%;
  text-align: left;
  width: 100%;

  .otp {
    border: 3px solid $color-primary;
    border-right: none;
    border-top: none;
    border-left: none;
    width: 30%;
    font-size: 32px;
    text-align: center;
    height: 80px;
    &:focus {
      outline: none;
    }
  }
  .otp-text-position {
    color: $color-input-select-placeholder;
    margin-left: 6px;
    text-align: left;
  }
}

.input-text {
  width: 100%;
  border-width: 0;
  border-bottom: unset;
  justify-content: left;
  background: transparent;
  font-size: 40px;
  letter-spacing: 75px;
  margin-left: 26px;
  &:focus {
    border-color: unset;
    border: unset;
    outline: unset;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.otp-bottom-line-holder {
  display: flex;
  margin-top: -1px;
  span {
    display: inline-block;
    height: 1px;
    width: 160px;
    margin-right: 14px;
    border-bottom: 3px solid $color-primary;
    background-color: $color-charcoal;
  }
}

//Mobile Phone CSS
@media only screen and (max-width: 768px) {
  .encore__otp__guide {
    padding: 15px;
  }

  .otp-bottom-line-holder {
    span {
      width: 100%;
    }
  }
}
