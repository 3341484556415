@import "../../sass/variables";

.encore__location__guide {
  padding: 15px 10%;
  text-align: left;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .encore__location__guide {
    padding: 15px;
  }
}
