@import "./assets/fonts/rawline.css";

body {
  font-family: "rawline", "Open Sans", "Segoe UI", sans-serif !important;
}

a {
  color: $color-primary;

  &:hover {
    color: $color-primary-dark;
  }
}

.MuiButton-root.button {
  border-radius: 32px !important;
  box-shadow: none !important;
  min-width: 120px !important;
  padding: 15px 22px;
  line-height: 18px;
  font-weight: 700;

  // &:not(:first-child):not(.MuiButton-fullWidth) {
  //   margin-left: 12px !important;
  // }

  &.MuiButton-containedPrimary {
    color: $color-white;
    background-color: $color-primary;

    &:hover {
      background-color: $color-primary-dark;
    }

    &.Mui-disabled {
      opacity: 0.5;
      pointer-events: none;
      color: $color-white;
      background-color: $color-primary;
    }
  }

  &.MuiButton-outlinedSecondary {
    color: $color-secondary;
    background-color: $color-outlined-button-text-bg;
    border-color: $color-outlined-button-text-border;

    &:hover {
      border-color: $color-outlined-button-text-border;
      background-color: $color-outlined-button-text-bg-hover;
    }

    &.Mui-disabled {
      opacity: 0.5;
      pointer-events: none;
      color: $color-secondary;
      background-color: $color-outlined-button-text-bg;
    }
  }

  &:focus,
  &:active {
    outline: inherit;
  }

  span {
    text-transform: capitalize;
  }

  .MuiButton-iconSizeMedium > *:first-child {
    font-size: 18px;
  }
}

.MuiDialog-root {
  .MuiDialog-container {
    height: 100%;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */

    .MuiPaper-rounded {
      border-radius: 8px;
    }

    .MuiDialog-paperWidthSm {
      min-width: 500px;
    }

    .MuiDialogTitle-root {
      color: $color-modal-title-text;
      background-color: $color-primary;
      h2 {
        text-align: center;
        font-size: 20px;
        font-weight: 800;
        line-height: 24px;
      }
      .close-button {
        position: absolute;
        right: 8px;
        top: 0px;
        padding: 8px;
        color: white;
        outline: none;
        &:hover {
          background: none;
        }
      }
    }

    .MuiDialogContent-root,
    .MuiDialogContentText-root {
      color: $color-modal-content-text;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      .header {
        font-size: 16px;
        font-weight: 700;
        display: block;
      }
      .body {
        margin-top: 10px;
        display: block;
      }
    }

    .MuiDialogContent-root {
      padding: 24px 24px 12px 24px;
    }

    .MuiDialogActions-root {
      padding: 16px 24px;
      justify-content: space-around;
      button:not(:only-child):not(.MuiFab-sizeSmall) {
        width: 100%;
      }
      &.fab__group {
        justify-content: flex-end;
      }
    }
  }
}

.MuiSelect-root {
  &:focus,
  &:active {
    background-color: transparent !important;
  }
}

.MuiFab-root {
  box-shadow: none !important;

  &:not(:first-child) {
    margin-left: 12px !important;
  }

  &.MuiFab-primary {
    color: $color-white;
    background-color: $color-primary;
    &:hover {
      background-color: $color-primary-dark;
    }
  }

  &.MuiFab-secondary {
    color: $color-secondary;
    background-color: $color-outlined-button-fab-bg;
    &:hover {
      background-color: $color-outlined-button-fab-bg-hover;
    }
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.MuiAccordion-root {
  &:before {
    opacity: 0 !important;
  }
  .MuiAccordionSummary-content {
    margin: 15px 0px;
    .MuiFormControlLabel-labelPlacementStart {
      margin-bottom: 0;
    }
  }
}

p.input-label {
  color: $color-input-label-text;
  font-size: 14px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;

  &.heading {
    font-size: 16px;
    font-weight: bold;
  }
}

div.input-field {
  width: 100%;
  border-width: 0;
  justify-content: left;
  background: transparent;
  color: $color-input-value-text;
  font-weight: bold;
  text-align: left;
  height: 48px;

  &.Mui-disabled {
    background: $color-input-bg;
  }

  &:not(.MuiInputBase-fullWidth) {
    max-width: 450px;
  }

  &.MuiOutlinedInput-root {
    height: 48px;
  }

  &.select {
    background: $color-input-bg;
    color: $color-input-select-placeholder;
    font-size: 14px;
    font-weight: 400;

    &.Mui-disabled {
      color: $color-input-value-text-disabled;
    }

    &.selected {
      color: $color-input-value-text;
      font-weight: 700;
    }

    .MuiOutlinedInput-notchedOutline {
      border-width: 0px;
      border-bottom-width: 1px;
      border-color: $color-input-border-inactive;
      border-radius: 4px 4px 0px 0px;
    }

    &:hover:not(.Mui-disabled) {
      .MuiOutlinedInput-notchedOutline {
        border-width: 0px;
        border-bottom-width: 1px;
        border-color: $color-input-border-hover;
      }
    }
  }

  input {
    color: $color-input-value-text;
    font-weight: bold;
    text-align: left;
    padding: 18.5px 14px;

    &.Mui-disabled {
      color: $color-input-value-text-disabled;
    }
  }

  &.Mui-focused:not(.Mui-disabled) {
    &:after {
      border-bottom: 2px solid;
      border-color: $color-input-border-active;
    }
  }

  &.Mui-error:not(.Mui-focused):not(:hover) {
    &:after {
      border-bottom: 1px solid;
      border-color: $color-error;
    }
  }

  .MuiInputAdornment-positionStart {
    font-weight: 500;
    margin-right: 15px;
  }

  &.MuiInput-underline.Mui-disabled:before,
  &:before {
    border-bottom: 1px solid;
    border-color: $color-input-border-inactive;
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: $color-input-border-inactive;
  }

  &:hover:not(.Mui-disabled) {
    &:before {
      border-bottom: 2px solid;
      border-color: $color-input-border-hover;
    }

    &:after {
      border-bottom: 2px solid;
      border-color: $color-input-border-hover;
    }

    .MuiOutlinedInput-notchedOutline {
      border-width: 2px;
      border-color: $color-input-border-hover;
    }
  }
}

div.error-text {
  color: $color-error;
  margin-top: 0.25rem;
  text-align: left;
  &.center {
    text-align: center;
  }
}
