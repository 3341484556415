@import "../../sass/variables";

.encore__financing__ui {
  padding: 15px 10%;
  text-align: center;
  width: 100%;
  color: $color-charcoal;
  max-width: calc(700px + 20%);

  .credit-limit-container {
    margin-top: 25px;
    text-align: left;
    padding: 24px;
    border-radius: 8px;
    color: $color-dark-yellow;
    background: $color-fade-yellow-pale;

    .info-holder {
      margin-top: 16px;
      display: flex;
      .product-holder {
        width: 40%;
      }
      .product-info-group {
        width: 60%;
        display: flex;
        .product-info-holder {
          width: 50%;
        }
      }
    }
    .label-holder {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .value-holder {
      margin-top: 6px;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 29px;
    }
    .desc-value-holder {
      margin-top: 6px;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }
    .price-holder {
      margin-top: 10px;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 37px;
      & + hr {
        margin: 24px 0px;
        height: 1px;
        background-color: $color-dark-yellow;
        border: none;
      }
    }
    .collapse-click {
      margin-top: 42px;
      cursor: pointer;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      svg {
        margin-top: -5px;
      }
    }
    .collapse-content {
      margin-top: 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      animation: fadeIn ease 0.5s;
      -webkit-animation: fadeIn ease 0.5s;
    }
  }

  .body-container {
    margin-top: 25px;
    text-align: left;

    .body-content {
      .title {
        font-size: 24px;
        font-weight: 800;
        line-height: 29px;
      }
      .desc {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
      }

      .button-container {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        text-align: left;
        border-radius: 10px;
        padding: 16px;
        cursor: pointer;
        border: 1px solid $color-input-border-active;
        background-color: $color-white;

        .button-text-holder {
          padding-right: 16px;
          .title {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
          }
          .desc {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $color-charcoal;
            b {
              font-weight: 700;
            }
          }
        }

        .button-icon-holder {
          svg {
            width: 24px;
            height: 100%;
          }
        }

        &.gold {
          border-color: $color-dark-yellow;
          &:hover {
            background-color: $color-dark-yellow;
            border-color: $color-dark-yellow;
          }
          .title {
            color: $color-dark-yellow;
          }
          svg {
            color: $color-dark-yellow;
          }
        }

        &.red {
          border-color: $color-primary;
          &:hover {
            background-color: $color-primary;
            border-color: $color-primary;
          }
          .title {
            color: $color-primary;
          }
          svg {
            color: $color-primary;
          }
        }

        &:hover {
          .button-text-holder {
            .title {
              color: $color-white;
            }
            .desc {
              color: $color-white;
            }
          }
          .button-icon-holder {
            svg {
              color: $color-white;
            }
          }
        }
      }
    }
  }

  .offline_holding {
    .title {
      font-size: 32px;
      font-weight: 800;
      margin-top: 136px;
    }
    .desc {
      font-size: 16px;
      margin-top: 16px;
    }
    .banner {
      margin-top: 26px;
      img {
        width: 100%;
        object-fit: cover;
        max-width: 746px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .encore__financing__ui {
    padding: 15px;
    .credit-limit-container {
      .info-holder {
        display: unset;
        .product-holder {
          width: 100%;
        }
        .product-info-group {
          width: 100%;
          margin-top: 24px;
          .product-info-holder {
            margin-top: 0px;
          }
        }
      }
    }

    .body-container {
      .body-content {
        .button-container {
          .button-text-holder {
            padding-right: 0px;
          }
          .button-icon-holder {
            img {
              display: none;
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
