@import "../../sass/variables";

.page-loader{
  z-index: 1000;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 0;

  .loader-holder {
    margin-top: -5px;

    .loader {

      &.text-primary {
        color: $color-primary !important;
      }
    }
  }
}