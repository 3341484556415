@import "../../sass/variables";

.footer-logo {
  width: 160px;
  margin: 0px auto 20px;
}

.footer3 {
  background-color: $color-footer-background;
  padding: 15px;
  text-align: center;

  .help-link {
    a {
      text-decoration: none;
      color: $color-white;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .contact-info {
    margin-top: 12px;
    display: inline-flex;
    vertical-align: middle;

    a:not(:last-child) {
      margin-right: 25px;
    }

    a,
    label,
    p {
      font-size: 14px;
      margin-bottom: 0px;
      text-decoration: none;
      color: $color-white;
      display: inline-flex;

      svg:first-child,
      img.icon {
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .footer-logo {
    width: 120px;
    margin: 0px auto 20px;
  }
}
