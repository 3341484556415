@import "../../sass/variables";

.header-container {
  position: relative;
  color: $color-white;
  min-height: 160px;
  width: 100%;
  padding: 40px 24px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  max-width: calc(700px + 20%);
  margin: auto;

  .header-bg {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }
  .title {
    text-align: left;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 37px;
  }
  .desc {
    margin-top: 16px;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }
  .subDesc {
    margin-top: 6px;
    margin-top: 16px;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }
}

button.encore__page__back_holder {
  padding: unset;
  margin-bottom: 10px;
  outline: none;
}

div.encore__page__upper {
  width: 100%;
  max-width: 800px;
  margin-bottom: 42px;

  .encore__identifier {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: $color-primary;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }
  .encore__title {
    margin-top: 32px;
    font-size: 24px;
    font-weight: 800;
    line-height: 29px;
    color: $color-charcoal;
  }
  .encore__desc {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: $color-charcoal;
    ul {
      font-size: 14px;
      line-height: 21px;
    }
  }
  .encore__banner {
    margin-top: 16px;
  }
}

div.encore__reminder__container {
  margin-top: 32px;
  padding: 10px 0px;
  .encore__major__title {
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
  }
  .encore__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    color: $color-charcoal;
    text-transform: uppercase;
    margin-top: 8px;
    &.primary {
      color: $color-primary;
    }
    &.error {
      color: $color-error;
    }
  }
  .encore__reminder {
    margin-top: 32px;
    display: flex;
    flex-wrap: unset;
    .encore__container {
      flex: 0 1 25%;
      padding-right: 16px;
      .encore__title {
        color: $color-charcoal;
        &.primary {
          color: $color-primary;
        }
        &.error {
          color: $color-error;
        }
      }
      .encore__desc {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: $color-charcoal;
      }
    }
  }

  //Mobile Phone CSS
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
    .encore__reminder {
      flex-direction: row;
      flex-wrap: wrap;
      .encore__container {
        flex: 0 1 50%;
        .encore__desc {
          padding-bottom: 16px;
        }
      }
    }
  }
}

.encore__border {
  margin-top: 10px;
  border: 1px solid $color-charcoal;
  border-radius: 10px;
  padding: 18px;

  &.primary {
    color: $color-primary;
    border: 1px solid $color-primary;
  }
}

.encore__next__button {
  margin-top: 36px;

  & + .encore__next__button {
    margin-top: 16px;
  }

  .button {
    max-width: 350px;
    width: 100%;
    align-self: center;
    &:focus {
      outline: none !important;
    }
  }
}

.encore__section__title {
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: $color-charcoal;
  a {
    color: $color-primary;
  }
}

.encore__financing__error__ui {
  padding: 15px 10%;
  text-align: center;
  width: 100%;
  color: $color-charcoal;
  max-width: calc(700px + 20%);
}

//Mobile Phone CSS
@media only screen and (max-width: 768px) {
  .encore__financing__error__ui {
    padding: 15px;
  }

  .encore__next__button {
    .button {
      & + .button {
        margin-top: 10px;
      }
    }
  }
}
