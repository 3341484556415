@import '../../sass/variables';

.dialog-ic-tutorial {
  .center-text {
    padding: 25px;
    text-align: center !important;
    width: 100% !important;
  }

  .icCaptureTutorialModal {
    padding: 10px;
    .icCaptureTutorialModal__header {
      display: flex;
      justify-content: space-between;

      .btn-close {
        cursor: pointer;
      }
    }
  }

  .arrowIconStyle {
    color: $color-primary !important;
    margin-top: 350%;
    cursor: pointer;
  }

  .arrowIconStyleHidden {
    visibility: hidden;
  }

  .closeIconIcTutorial {
    margin-left: 88% !important;
    margin-top: 4% !important;
    cursor: pointer;
  }

  .button_holder {
    margin: 35px 0px 0px 0px;

    .buttonStyle {
      max-width: 450px;
      width: 100%;
      align-self: center;
      &:focus {
        outline: none !important;
      }
      & + .button {
        margin-top: 10px;
      }
    }
  }
  .title {
    font-weight: 900 !important;
    font-size: 20px !important;
  }

  .Carousel-active-4 {
    color: $color-primary !important;
  }

  .Carousel-indicator-3 {
    color: #9bb7c0;
    cursor: pointer;
    font-size: 15px;
    transition: 200ms;
  }

  .logo-container-ic-tutorial {
    display: flex;
    align-items: center;
    overflow-x: hidden;
    margin-left: 9%;
    .logo-ic-tutorial {
      width: 100%;
      object-fit: cover;
    }
  }
}
