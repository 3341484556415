@import "../../sass/variables";

.encore__twelve__statement__guide {
  padding: 15px 10%;
  text-align: left;
  width: 100%;
}

//Mobile Phone CSS
@media only screen and (max-width: 768px) {
  .encore__twelve__statement__guide {
    padding: 15px;
  }
}
