@import "../../sass/variables";

.encore__welcome {
  padding: 15px 10%;
  text-align: left;
  width: 100%;

  .div-link-primary {
    margin-top: 42px;
    color: $color-primary;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: $color-primary-dark;
    }
  }
}

//Mobile Phone CSS
@media only screen and (max-width: 768px) {
  .encore__welcome {
    padding: 15px;
  }
}
