@import '../../sass/variables';

.dialog-selfie-tutorial {
  .center-text {
    padding: 25px;
    text-align: center !important;
    width: 100% !important;
  }

  .selfieCaptureTutorialModal {
    padding: 10px;
    .selfieCaptureTutorialModal__header {
      display: flex;
      justify-content: space-between;

      .btn-close {
        cursor: pointer;
      }
    }
  }

  .arrowIconStyle {
    color: $color-primary !important;
    margin-top: 160%;
    cursor: pointer;
  }

  .arrowIconStyleHidden {
    visibility: hidden;
  }

  .closeIconSelfieTutorial {
    margin-left: 80% !important;
    margin-top: 4% !important;
    cursor: pointer;
  }

  .button_holder {
    margin: 35px 0px 0px 0px;

    .buttonStyle {
      max-width: 450px;
      width: 100%;
      align-self: center;
      &:focus {
        outline: none !important;
      }
      & + .button {
        margin-top: 10px;
      }
    }
  }

  .title {
    font-weight: 900 !important;
    font-size: 20px !important;
  }

  .Carousel-active-4 {
    color: $color-primary !important;
  }

  .Carousel-indicator-3 {
    color: #9bb7c0;
    cursor: pointer;
    font-size: 15px;
    transition: 200ms;
  }

  // .jss1 {
  //     background: none!important;
  //     background-size: unset!important;
  //     background-repeat: unset!important;
  //     background-image: none!important;
  // }
}
