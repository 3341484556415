@import "variables";
@import "rebrand";

.page {
  // min-height: -webkit-fill-available;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $color-white;

  .page-container {
    flex: auto;
    display: flex;
    align-items: center;
  }

  .page-container-full-height {
    padding: 0px 15px 15px 15px;
    flex: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

p:last-child {
  margin-bottom: 0px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.link {
  color: $color-primary;
  cursor: pointer;

  &:hover {
    color: $color-primary-dark;
    text-decoration: underline;
  }
}

.blur {
  filter: blur(3px);
  -webkit-filter: blur(3px);
}
