@import "../../sass/variables";

.acp_mobius_landing {
  text-align: left;
  .language-movable {
    position: fixed;
    top: 50%;
    background-color: $color-custom-blue-dark;
    padding: 10px;
    text-align: center;
    border-radius: 0 10px 10px 0;
    z-index: 1;
    .ring {
      border: 2px solid $color-dark-yellow;
      border-radius: 20px;
    }
  }
  .header {
    background-color: $color-white;
    padding: 0 20px 0 20px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    .item {
      &-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .subheader {
    background-color: $color-grey-g300;
    padding: 20px 141px 20px 158px;
    height: 61px;
    display: flex;
    justify-content: space-between;
    .button {
      margin-top: -4%;
      padding: 2px 10px 2px 10px;
    }
  }
  .header-bg {
    background: url("../../assets/images/MobiusACPLanding/products_Product_Page_Hero_Banner@2x.png") center top / cover
      no-repeat;
    //backgroudn-image: url("../../assets/images/MobiusACPLanding/header-bg.png");
    //background-size:100% 100%;
    min-height: 570px;
    min-width: 20px;
    padding: 50px 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-align: left;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
    .header-content {
      padding: 20px 142px 20px 158px;
      max-width: 820px;
      .button {
        width: 200px;
      }
    }
  }
  .first-body {
    padding: 40px 142px 40px 158px;
    display: flex;
    .left {
      flex: 0.6;
      .image-text {
        display: flex;
      }
      .key-terms {
        .content {
          display: flex;
          flex-wrap: wrap;
          .item {
            width: 50%;
            margin-top: 4%;
            display: flex;
            flex-direction: column;
            // justify-content: space-around;
          }
        }
      }
    }
    .middle {
      flex: 0.1;
    }
    .right {
      flex: 0.3;
      .upper-content {
        // background-color: $color-grey-g300;
        border-radius: 6px;
        padding: 40px;
        li {
          line-height: 24px;
        }
        a {
          color: $color-primary;
        }
        .left-border {
          border-left: 2px solid $color-grey-g200;
        }
      }
    }
  }
  .second-body {
    background-color: $color-grey-g300;
    .content {
      padding: 80px 142px 80px 158px;
      .covid {
        display: flex;
        align-items: center;
      }
      .great-eastern {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: $color-white;
        padding: 10px;
        width: 65%;
      }
      .button {
        width: 25%;
      }
    }
  }
  .footer {
    padding: 80px 142px 80px 158px;
    .upper {
      display: flex;
      gap: 20%;
      .content {
        display: flex;
        margin-top: 2%;
        gap: 20%;
      }
      .social-media {
        display: flex;
        flex-wrap: wrap;
        .item {
          display: flex;
          align-items: center;
          width: 50%;
          margin-top: 2%;
        }
        a:hover {
          text-decoration: none;
        }
      }
    }
  }
  .footer-lower {
    border-top: 1px solid $color-grey-g300;
    .lower {
      padding: 20px 142px 20px 158px;
      height: 29px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

@media only screen and (max-device-width: 480px) {
  .acp_mobius_landing {
    text-align: left;
    .header {
      // background-color: $color-grey-g300;
      padding: 0 0 0 20px;
      height: 40px;
      display: flex;
      justify-content: center;
      .item {
        &-flex {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .subheader {
      background-color: $color-grey-g300;
      padding: 20px 0px 20px 0px;
      height: 61px;
      display: flex;
      justify-content: center;
      .button {
        margin-top: -4%;
        padding: 2px 10px 2px 10px;
      }
    }
    .header-bg {
      background: url("../../assets/images/MobiusACPLanding/products_Product_Page_Hero_Banner@2x.png") center top /
        cover no-repeat;
      //backgroudn-image: url("../../assets/images/MobiusACPLanding/header-bg.png");
      //background-size:140% 100%;
      min-height: unset;
      min-width: unset;
      padding: 50px 0px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      text-align: left;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
      .header-content {
        padding: 40px 20px 40px 20px;
        .button {
          width: 200px;
        }
      }
    }
    .first-body {
      padding: unset;
      display: flex;
      flex-direction: column;
      .left {
        padding: 40px 20px 40px 20px;
        flex: 1;
        .image-text {
          display: flex;
          flex-direction: column;
        }
        .key-terms {
          // display: block;
          .content {
            display: flex;
            flex-wrap: wrap;
            .item {
              width: 100%;
              margin-bottom: 14%;
              display: flex;
              flex-direction: column;
              // justify-content: space-around;
            }
          }
        }
      }
      .middle {
        flex: 1;
      }
      .right {
        flex: 1;
        .upper-content {
          // background-color: $color-grey-g200;
          border-radius: 6px;
          padding: 30px;
          li {
            line-height: 24px;
          }
          a {
            color: $color-primary;
          }
          .left-border {
            border-left: 2px solid $color-grey-g200;
          }
        }
      }
    }
    .second-body {
      background-color: $color-grey-g300;
      margin-top: 40px;
      .content {
        padding: 40px 20px 40px 20px;
        .covid {
          display: flex;
          align-items: center;
        }
        .great-eastern {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          background-color: $color-white;
          width: 100%;
          padding: 10px;
          text-align: right;
        }
        .button {
          width: 100%;
        }
      }
    }
    .footer {
      padding: 40px 20px 40px 20px;
      .upper {
        display: flex;
        gap: 35%;
        .content {
          display: flex;
          flex-direction: column;
          margin-top: 2%;
          gap: 20%;
        }
        .social-media {
          display: flex;
          flex-wrap: wrap;
          .item {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 2%;
          }
        }
      }
    }
    .footer-lower {
      border-top: 1px solid $color-grey-g300;
      height: 80px;
      .lower {
        padding: 20px 30px 20px 30px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 568px) {
  .acp_mobius_landing {
    text-align: left;
    .header {
      // background-color: $color-grey-g200;
      padding: 0 0 0 20px;
      height: 40px;
      display: flex;
      justify-content: center;
      .item {
        &-flex {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .subheader {
      background-color: $color-grey-g300;
      padding: 20px 0px 20px 0px;
      height: 61px;
      display: flex;
      justify-content: center;
      .button {
        margin-top: -4%;
        padding: 2px 10px 2px 10px;
      }
    }
    .header-bg {
      background: url("../../assets/images/MobiusACPLanding/products_Product_Page_Hero_Banner@2x.png") center top /
        cover no-repeat;
      //backgroudn-image: url("../../assets/images/MobiusACPLanding/header-bg.png");
      //background-size:140% 100%;
      min-height: unset;
      min-width: unset;
      padding: 50px 0px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      text-align: left;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
      .header-content {
        padding: 20px 30px 20px 30px;
        .button {
          width: 200px;
        }
      }
    }
    .first-body {
      padding: unset;
      display: flex;
      flex-direction: column;
      .left {
        padding: 40px 30px 40px 30px;
        flex: 1;
        .image-text {
          display: flex;
          flex-direction: column;
        }
        .key-terms {
          // display: block;
          .content {
            display: flex;
            flex-wrap: wrap;
            .item {
              width: 100%;
              margin-bottom: 14%;
              display: flex;
              flex-direction: column;
              // justify-content: space-around;
            }
          }
        }
      }
      .middle {
        flex: 1;
      }
      .right {
        flex: 1;
        .upper-content {
          // background-color: $color-grey-g200;
          border-radius: 6px;
          padding: 30px;
          li {
            line-height: 24px;
          }
          a {
            color: $color-primary;
          }
          .left-border {
            border-left: 2px solid $color-grey-g200;
          }
        }
      }
    }
    .second-body {
      background-color: $color-grey-g300;
      margin-top: 40px;
      .content {
        padding: 40px 30px 40px 30px;
        .covid {
          display: flex;
          align-items: center;
        }
        .great-eastern {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          background-color: $color-white;
          width: 100%;
          padding: 10px;
          text-align: right;
        }
        .button {
          width: 100%;
        }
      }
    }
    .footer {
      padding: 40px 30px 40px 30px;
      .upper {
        display: flex;
        gap: 30%;
        .content {
          display: flex;
          flex-direction: column;
          margin-top: 2%;
          gap: 20%;
        }
        .social-media {
          display: flex;
          flex-wrap: wrap;
          .item {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 2%;
          }
        }
      }
    }
    .footer-lower {
      border-top: 1px solid $color-grey-g300;
      height: 80px;
      .lower {
        padding: 20px 30px 20px 30px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
@media only screen and (min-device-width: 569px) and (max-device-width: 959px) {
  .acp_mobius_landing {
    text-align: left;
    .header {
      // background-color: $color-grey-g200;
      padding: 0 0 0 20px;
      height: 40px;
      display: flex;
      justify-content: center;
      .item {
        &-flex {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .subheader {
      background-color: $color-grey-g300;
      padding: 20px 0px 20px 0px;
      height: 61px;
      display: flex;
      justify-content: center;
      .button {
        margin-top: -4%;
        padding: 2px 10px 2px 10px;
      }
    }
    .header-bg {
      background: url("../../assets/images/MobiusACPLanding/products_Product_Page_Hero_Banner@2x.png") center top /
        cover no-repeat;
      //backgroudn-image: url("../../assets/images/MobiusACPLanding/header-bg.png");
      //background-size:140% 100%;
      min-height: unset;
      min-width: unset;
      padding: 50px 0px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      text-align: left;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
      .header-content {
        padding: 20px 30px 20px 30px;
        .button {
          width: 200px;
        }
      }
    }
    .first-body {
      padding: unset;
      display: flex;
      flex-direction: column;
      .left {
        padding: 40px 30px 40px 30px;
        flex: 1;
        .image-text {
          display: flex;
          flex-direction: column;
        }
        .key-terms {
          // display: block;
          .content {
            display: flex;
            flex-wrap: wrap;
            .item {
              width: 100%;
              margin-bottom: 14%;
              display: flex;
              flex-direction: column;
              // justify-content: space-around;
            }
          }
        }
      }
      .middle {
        flex: 1;
      }
      .right {
        flex: 1;
        .upper-content {
          // background-color: $color-grey-g200;
          border-radius: 6px;
          padding: 30px;
          li {
            line-height: 24px;
          }
          a {
            color: $color-primary;
          }
          .left-border {
            border-left: 2px solid $color-grey-g200;
          }
        }
      }
    }
    .second-body {
      background-color: $color-grey-g300;
      margin-top: 40px;
      .content {
        padding: 40px 30px 40px 30px;
        .covid {
          display: flex;
          align-items: center;
        }
        .great-eastern {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          background-color: $color-white;
          width: 100%;
          padding: 10px;
          text-align: right;
        }
        .button {
          width: 100%;
        }
      }
    }
    .footer {
      padding: 40px 30px 40px 30px;
      .upper {
        display: flex;
        gap: 30%;
        .content {
          display: flex;
          flex-direction: column;
          margin-top: 2%;
          gap: 20%;
        }
        .social-media {
          display: flex;
          flex-wrap: wrap;
          .item {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 2%;
          }
        }
      }
    }
    .footer-lower {
      border-top: 1px solid $color-grey-g300;
      height: 80px;
      .lower {
        padding: 20px 30px 20px 30px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
@media only screen and (min-device-width: 960px) and (max-device-width: 1024px) {
  .acp_mobius_landing {
    text-align: left;
    .header {
      // background-color: $color-grey-g200;
      padding: 0 20px 0 20px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      .item {
        &-flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .subheader {
      background-color: $color-grey-g300;
      padding: 20px 72px 20px 88px;
      height: 61px;
      display: flex;
      justify-content: space-between;
      .button {
        margin-top: -4%;
        padding: 2px 10px 2px 10px;
      }
    }
    .header-bg {
      background: url("../../assets/images/MobiusACPLanding/products_Product_Page_Hero_Banner@2x.png") center top /
        cover no-repeat;
      //backgroudn-image: url("../../assets/images/MobiusACPLanding/header-bg.png");
      //background-size:100% 100%;
      min-height: 570px;
      min-width: 20px;
      padding: 50px 0px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      text-align: left;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
      .header-content {
        padding: 20px 72px 20px 88px;
        .button {
          width: 200px;
        }
      }
    }
    .first-body {
      padding: 20px 72px 20px 88px;
      display: flex;
      .left {
        flex: 0.6;
        .image-text {
          display: flex;
        }
        .key-terms {
          .content {
            display: flex;
            flex-wrap: wrap;
            .item {
              width: 50%;
              margin-top: 4%;
              display: flex;
              flex-direction: column;
              // justify-content: space-around;
            }
          }
        }
      }
      .middle {
        flex: 0.05;
      }
      .right {
        flex: 0.35;
        .upper-content {
          // background-color: $color-grey-g200;
          border-radius: 6px;
          padding: 40px;
          li {
            line-height: 24px;
          }
          a {
            color: $color-primary;
          }
          .left-border {
            border-left: 2px solid $color-grey-g200;
          }
        }
      }
    }
    .second-body {
      background-color: $color-grey-g300;
      .content {
        padding: 20px 72px 20px 88px;
        .covid {
          display: flex;
          align-items: center;
        }
        .great-eastern {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background-color: $color-white;
          width: 100%;
          padding: 10px;
        }
        .button {
          width: 40%;
        }
      }
    }
    .footer {
      padding: 20px 72px 20px 88px;
      .upper {
        display: flex;
        gap: 20%;
        .content {
          display: flex;
          margin-top: 2%;
          gap: 20%;
        }
        .social-media {
          display: flex;
          flex-wrap: wrap;
          .item {
            display: flex;
            align-items: center;
            width: 50%;
            margin-top: 2%;
          }
        }
      }
    }
    .footer-lower {
      border-top: 1px solid $color-grey-g300;
      height: 29px;
      .lower {
        padding: 20px 72px 20px 88px;
        height: 29px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.primary-color {
  color: $color-custom-blue-dark;

  &-dark {
    color: $color-custom-blue-dark;
  }
  &-light {
    color: $color-charcoal;
  }
  &-light-soft {
    color: $color-grey-g50;
  }
  &-very-light {
    color: $color-grey-g100;
  }
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.font-size {
  &-10 {
    font-size: 10px;
  }
  &-12 {
    font-size: 12px;
  }
  &-14 {
    font-size: 14px;
  }
  &-16 {
    font-size: 16px;
  }
  &-21 {
    font-size: 21px;
  }
  &-48 {
    font-size: 48px;
  }
}

.line-height {
  &-18 {
    line-height: 18px;
  }
  &-21 {
    line-height: 21px;
  }
  &-24 {
    line-height: 24px;
  }
}

.mt {
  &-10 {
    margin-top: 10%;
  }
}

.font-weight {
  &-600 {
    font-weight: 600;
  }
}

.text-align-right {
  text-align: right;
}

.margin-top-minus-9 {
  margin-top: -9%;
}

.table-head {
  background-color: $color-charcoal;
  color: $color-white;
  // border-radius: 8px 8px 0px 0px;
  width: 746px;
  height: 67px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.header-closed {
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s,
    opacity 4s linear;
}
