@import "../../sass/variables";

.encore__basic_detail__guide {
  padding: 15px 10%;
  text-align: left;
  width: 100%;

  .formik-form {
    .form-flex {
      .item {
        max-width: 450px;
        display: flex;
      }
    }

    .checkbox-container {
      .agreement-checkbox {
        margin-right: 8px;
        cursor: pointer;
        + span {
          text-align: left;
        }
      }
    }
  }
}

//Mobile Phone CSS
@media only screen and (max-width: 768px) {
  .encore__basic_detail__guide {
    padding: 15px;
  }
}
