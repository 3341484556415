@import "../../sass/variables";

.encore__business_detail__guide {
  padding: 15px 10%;
  text-align: left;
  width: 100%;

  .input-label-notes {
    font-weight: bold;
    font-style: italic;
  }
}

//Mobile Phone CSS
@media only screen and (max-width: 768px) {
  .encore__business_detail__guide {
    padding: 15px;
  }
}
