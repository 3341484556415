@import "../../sass/variables";

.encore__kycReviewNameNric {
  padding: 15px 10%;
  text-align: left;
  width: 100%;

  .form-flex {
    .item {
      max-width: 450px;
      display: flex;
    }
  }
}

//Mobile Phone CSS
@media only screen and (max-width: 768px) {
  .encore__kycReviewNameNric {
    padding: 15px;
  }
}
